<template>
    <div class="report-search-words-table">
        <md-table-card>
            <md-card-header>
                <h4 style="margin:0">Search Results</h4>
            </md-card-header>
            <md-card-content>
                <div class="common-list">
                    <form @submit.prevent="handleSubmit">
                        <md-toolbar class="md-transparent">
                            <div class="md-toolbar-container">
                                <span style="flex:1"></span>
                                <date-range-picker :showUpcoming="false" v-model="dateRange"></date-range-picker>
                                <md-input-container md-inline style="width:150px">
                                    <md-input placeholder="Search..." v-model="q"></md-input>
                                    <md-icon>search</md-icon>
                                </md-input-container>
                                <md-button class="md-primary md-raised" type="submit">Submit</md-button>
                            </div>
                        </md-toolbar>
                    </form>
                    <md-table :md-sort="sortName" :md-sort-type="sortType" @sort="handleSort">
                        <md-table-header>
                            <md-table-row>
                                <md-table-head md-sort-by="_id">Word</md-table-head>
                                <md-table-head md-sort-by="count" style="width:20%">Count</md-table-head>
                            </md-table-row>
                        </md-table-header>
                        <md-table-body>
                            <template v-for="(r,ix) in records">
                                <md-table-row :key="ix">
                                    <md-table-cell>{{r._id}}</md-table-cell>
                                    <md-table-cell>{{r.count}}</md-table-cell>
                                </md-table-row>
                            </template>
                        </md-table-body>
                    </md-table>
                    <md-table-pagination :md-page="page.pageNo" :md-page-options="[5,10, 20, 50]" :md-size="page.pageSize" :md-total="page.total" @pagination="handlePagination" md-label="Rows" md-separator="of"></md-table-pagination>
                </div>
            </md-card-content>
        </md-table-card>
    </div>
</template>
<script>
import DateRangePicker from "@/components/DateRangePicker";
import moment from "moment-timezone";
export default {
    components: {
        DateRangePicker
    },
    data() {
        return {
            dateRange: [
                moment()
                    .startOf("day")
                    .subtract(12, "months")
                    .startOf("month")
                    .toDate(),
                moment()
                    .startOf("day")
                    .subtract(0, "months")
                    .endOf("month")
                    .toDate()
            ],
            records: [],
            page: {
                pageNo: 1,
                pageSize: 10
            },
            sort: undefined,
            q: undefined
        };
    },
    computed: {
        from() {
            if (!this.dateRange || this.dateRange == "All") {
                return undefined;
            }
            return this.dateRange[0] ? moment(this.dateRange[0]).toDate() : null;
        },
        to() {
            if (!this.dateRange || this.dateRange == "All") {
                return undefined;
            }
            return this.dateRange[1] ? moment(this.dateRange[1]).toDate() : null;
        },
        sortName() {
            return this.sort && this.sort.replace(/-/g, "");
        },
        sortType() {
            return /-/.test(this.sort) ? "desc" : "asc";
        }
    },

    methods: {
        async loadSearchWords() {
            let result = await this.$store.dispatch("crud/get", {
                api: "searchword/group",
                params: {
                    from: this.from,
                    to: this.to,
                    sort: this.sort,
                    q: this.q,
                    ...this.page
                }
            });

            this.records = result.data;
            this.page = result.page;
            this.sort = result.sort;
        },
        handleSort({ name, type }) {
            console.info(type);
            if (type == "asc") {
                this.sort = name;
            } else {
                this.sort = "-" + name;
            }
            this.page.pageNo = 1;
            this.loadSearchWords();
        },
        handlePagination({ page, size }) {
            this.page.pageNo = page;
            this.page.pageSize = size;
            this.loadSearchWords();
        },
        async handleSubmit() {
            await this.loadSearchWords();
        }
    },
    async mounted() {
        await this.loadSearchWords();
    }
};
</script>

