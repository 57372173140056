<template>
    <div class="report-search-words">
        <md-tabs @change="tabChange">
            <md-tab :md-active="tabIndex==0" md-label="Table View">
                <report-search-words-table v-if="tabIndex==0"></report-search-words-table>
            </md-tab>
            <md-tab :md-active="tabIndex==1" md-label="Chart View">
                <report-search-words-chart v-if="tabIndex==1"></report-search-words-chart>
            </md-tab>
        </md-tabs>
    </div>
</template>
<script>
import ReportSearchWordsTable from "@/components/Report/ReportSearchWordsTable";
import ReportSearchWordsChart from "@/components/Report/ReportSearchWordsChart";
export default {
    props: ["id"],
    components: {
        ReportSearchWordsTable,
        ReportSearchWordsChart
    },
    data() {
        return {
            tabIndex: 0,
            tabChangeCount: 0
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                console.info("id", this.id);
                if (this.id == "table") {
                    this.tabIndex = 0;
                } else if (this.id == "chart") {
                    this.tabIndex = 1;
                }
            }
        }
    },
    methods: {
        tabChange(index) {
            if (this.tabChangeCount) {
                if (index == 0) {
                    this.$router.push("/admin/reports/search-words/table");
                }
                if (index == 1) {
                    this.$router.push("/admin/reports/search-words/chart");
                }
            }
            this.tabChangeCount++;
        }
    },
    created() {
        if (!this.id) {
            // this.$router.replace("/admin/reports/search-words/table");
        }
    }
};
</script>