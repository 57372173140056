<template>
    <div class="report-search-words-chart">
        <md-table-card>
            <md-card-header>
                <h4 style="margin:0">Search Results</h4>
            </md-card-header>
            <md-card-content>
                <div class="common-list">
                    <form @submit.prevent="handleSubmit">
                        <md-toolbar class="md-transparent">
                            <div class="md-toolbar-container">
                                <span style="flex:1"></span>
                                <date-range-picker :showUpcoming="false" v-model="dateRange"></date-range-picker>
                                <md-input-container md-inline style="width:150px">
                                    <md-input placeholder="Search..." v-model="q"></md-input>
                                    <md-icon>search</md-icon>
                                </md-input-container>
                                <md-button class="md-primary md-raised" type="submit">Submit</md-button>
                            </div>
                        </md-toolbar>
                    </form>
                    <div class="chart-container" style="height:400px"></div>
                    <!-- <md-table :md-sort="sortName" :md-sort-type="sortType" @sort="handleSort">
                        <md-table-header>
                            <md-table-row>
                                <md-table-head md-sort-by="_id">Word</md-table-head>
                                <md-table-head md-sort-by="count" style="width:20%">Count</md-table-head>
                            </md-table-row>
                        </md-table-header>
                        <md-table-body>
                            <template v-for="(r,ix) in records">
                                <md-table-row :key="ix">
                                    <md-table-cell>{{r._id}}</md-table-cell>
                                    <md-table-cell>{{r.count}}</md-table-cell>
                                </md-table-row>
                            </template>
                        </md-table-body>
                    </md-table>
                    <md-table-pagination :md-page="page.pageNo" :md-page-options="[5,10, 20, 50]" :md-size="page.pageSize" :md-total="page.total" @pagination="handlePagination" md-label="Rows" md-separator="of"></md-table-pagination>-->
                </div>
            </md-card-content>
        </md-table-card>
    </div>
</template>
<script>
import echarts from "echarts";
import DateRangePicker from "@/components/DateRangePicker";
import moment from "moment-timezone";
export default {
    components: {
        DateRangePicker
    },
    data() {
        return {
            dateRange: [
                moment()
                    .startOf("day")
                    .subtract(12, "months")
                    .startOf("month")
                    .toDate(),
                moment()
                    .startOf("day")
                    .subtract(0, "months")
                    .endOf("month")
                    .toDate()
            ],
            records: [],
            q: undefined,
            chart: null
        };
    },
    computed: {
        from() {
            if (!this.dateRange || this.dateRange == "All") {
                return undefined;
            }
            return this.dateRange[0] ? moment(this.dateRange[0]).toDate() : null;
        },
        to() {
            if (!this.dateRange || this.dateRange == "All") {
                return undefined;
            }
            return this.dateRange[1] ? moment(this.dateRange[1]).toDate() : null;
        }
    },

    methods: {
        async loadSearchWords() {
            let result = await this.$store.dispatch("crud/get", {
                api: "searchword/group",
                params: {
                    from: this.from,
                    to: this.to,
                    q: this.q,
                    pageNo: 1,
                    pageSize: 10,
                    sort: "count"
                }
            });
            this.records = result.data;
            this.initChart();
        },
        async handleSubmit() {
            await this.loadSearchWords();
        },
        initChart() {
            let container = this.$el.querySelector(".chart-container");
            let xData = this.records.map(r => r._id);
            let yData = this.records.map(r => r.count);
            let options = {
                xAxis: {
                    data: xData,

                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    z: 10
                },
                yAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: "#999"
                        }
                    }
                },
                series: [
                    {
                        type: "bar",
                        // itemStyle: {
                        //     normal: {
                        //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: "#67bf5c" }, { offset: 0.5, color: "#73d667" }, { offset: 1, color: "#67b95d" }])
                        //     }
                        // },
                        label: {
                            // show:true
                        },
                        data: yData
                    }
                ]
            };

            this.chart = this.chart || echarts.init(container);
            this.chart.setOption(options);
        }
    },
    async mounted() {
        await this.loadSearchWords();
    }
};
</script>

